import React, { useEffect, useState, useRef } from 'react'
import propTypes from 'prop-types'
import Quill from 'quill'
import ColorPicker from '../../ColorPicker'
import DeleteItemDialogue from '../../DeleteItemDialogue'
import {
  answerUpdateValueToCorrect,
  answerUpdateValueToFalse,
  deleteAnswer,
  updateAnswer,
} from '../../../store/answers/answerAction'
import { updateBook } from '../../../store/books/bookAction'
import { useDispatch, useSelector } from 'react-redux'
import {
  relativeFontSize,
  relativePaddingsForBlockRow,
  removeEmptyHtmlTags,
  replaceSpacesWithNbsp,
} from '../../../utils'
import { useDebounce } from '../../../hooks/useDebounce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../Button'

const AnswerTextEditor = ({
  answer,
  question,
  parent,
  // dragging,
  pageType,
}) => {
  const editorRef = useRef()
  const quillRef = useRef()
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user)
  const currentBookId = useSelector((state) => state.books.currentBook)
  const book = useSelector((state) => state.books.books[currentBookId])

  const [undoLevelCount, setUndoLevelCount] = useState(0)
  // const [persistToolbar, setPersistToolbar] = useState(false)
  const [toolbarVisible, setToolbarVisible] = useState(false)

  const [editorContent, setEditorContent] = useState(null)
  const [dirty, setDirty] = useState(false)
  const [openDeleteContentModal, setOpenDeleteContentModal] = useState(false)
  const [openDeleteAnswerModal, setOpenDeleteAnswerModal] = useState(false)
  const [openColorModal, setOpenColorModal] = useState(false)
  const [changeFont, setChangeFont] = useState(false)
  const [fontColor, setFontColor] = useState('black')
  const [backgroundColor, setBackgroundColor] = useState(
    answer?.bgColor ? answer.bgColor : 'transparent'
  )

  useEffect(() => {
    if (answer && answer.html && !editorContent) {
      let html = removeEmptyHtmlTags(answer.html)
      setEditorContent(html)
    }
  }, [answer])

  useEffect(() => {
    if (
      editorRef.current &&
      !editorRef?.current?.classList.contains('ql-container')
    ) {
      const quill = new Quill(editorRef.current, {
        theme: 'snow',
        modules: {
          toolbar: `#toolbar-answer-${answer.id}`,
        },
        placeholder: 'Kirjoita vastaus...',
      })

      quill.root.setAttribute('spellcheck', false)
      quillRef.current = quill

      if (answer && answer.html) {
        const modifiedAnswer = replaceSpacesWithNbsp(answer.html)
        let html = removeEmptyHtmlTags(modifiedAnswer)
        quillRef.current.clipboard.dangerouslyPasteHTML(html)
      }
    }
  }, [editorContent, editorRef.current])

  useEffect(() => {
    const editorElement = editorRef.current
    if (editorElement) {
      const quillEditor = quillRef.current

      const handleFocus = () => {
        setToolbarVisible(true)
      }

      const handleBlur = (event) => {
        // Tarkista, onko blur-tapahtuman kohde työkalupalkin sisällä
        if (!event.relatedTarget || !event.relatedTarget.closest('.ql-toolbar')) {
          setToolbarVisible(false)
        }
      }

      const handleTextChange = () => {
        const html = quillEditor.root.innerHTML
        if (editorContent !== html) {
          setEditorContent(html)
          setDirty(true)
        }
      }

      quillEditor.on('text-change', handleTextChange)
      quillEditor.root.addEventListener('focus', handleFocus)
      quillEditor.root.addEventListener('blur', handleBlur, true)

      return () => {
        quillEditor.root.removeEventListener('focus', handleFocus)
        quillEditor.root.removeEventListener('blur', handleBlur)
        quillEditor.off('text-change', handleTextChange)
      }
    }
  }, [quillRef.current])

  // useEffect(() => {
  //   if (editorRef && editorRef.current && dragging) {
  //     setPersistToolbar(true)
  //     editorRef.current.ui.hide()
  //   } else if (editorRef && editorRef.current) {
  //     setPersistToolbar(false)
  //   }
  // }, [dragging])

  const saveAnswer = (answer, html) => {
    if (answer) {
      const updated = {
        ...answer,
        html,
      }
      if (answer.html !== updated.html) {
        dispatch(updateAnswer(updated))
        if (user && !user.anonymous) {
          const updatedBook = {
            ...book,
            updated: Date.now(),
          }
          dispatch(updateBook(updatedBook))
        }
      }
    }
    setEditorContent(html)
  }

  const setAnswerValue = (id, answerId) => {
    if (id === 'correct') {
      dispatch(answerUpdateValueToCorrect(answerId, book))
    } else if (id === 'false') {
      dispatch(answerUpdateValueToFalse(answerId, book))
    }
  }

  const debouncedEditorState = useDebounce(editorContent, 1000)

  const debouncedSaveAnswer = async (html) => {
    saveAnswer(answer, html)
  }

  useEffect(() => {
    if (dirty) {
      debouncedSaveAnswer(debouncedEditorState)
    }
  }, [debouncedEditorState])

  const emptyContents = () => {
    setDirty(true)
    saveAnswer(answer, null)
    if (quillRef.current) {
      quillRef.current.setText('')
    }
  }

  useEffect(() => {
    const quill = quillRef.current
    if (changeFont && quill) {
      quill.focus()
      quill.format('color', fontColor)
      setUndoLevelCount(undoLevelCount + 1)
    }
  }, [fontColor])

  useEffect(() => {
    if (!changeFont && editorRef && editorRef.current && editorRef.current.getBody) {
      editorRef.current.getBody().style.backgroundColor = backgroundColor
    }
  }, [backgroundColor])

  const saveBackgroundColor = () => {
    const updatedAnswer = {
      ...answer,
      bgColor: backgroundColor,
    }
    dispatch(updateAnswer(updatedAnswer))
    editorRef.current.focus()
    setOpenColorModal(false)
  }

  const updateFontColor = () => {
    setOpenColorModal(false)
    setChangeFont(false)
    editorRef.current.focus()
    setUndoLevelCount(0)
    if (
      changeFont &&
      editorRef &&
      editorRef.current &&
      editorRef.current.selection
    ) {
      if (editorRef.current.formatter.match('textcolor')) {
        editorRef.current.formatter.remove('textcolor')
        editorRef.current.formatter.apply('textcolor')
        setEditorContent(editorRef.current.getContent())
        setDirty(true)
      } else {
        editorRef.current.formatter.apply('textcolor')
        setEditorContent(editorRef.current.getContent())
      }
    }
  }

  const cancelAndCloseColorPicker = () => {
    if (!changeFont && answer) {
      setBackgroundColor(answer.bgColor)
      editorRef.current.focus()
    } else if (
      editorRef &&
      editorRef.current &&
      editorRef.current.undoManager &&
      editorRef.current.undoManager.hasUndo()
    ) {
      let counter = undoLevelCount
      while (counter > 0) {
        editorRef.current.undoManager.undo()
        counter--
      }
      editorRef.current.focus()
      setUndoLevelCount(0)
    }
    closeColorPicker()
  }

  const closeColorPicker = () => {
    setOpenColorModal(false)
    if (setChangeFont) {
      setChangeFont(false)
    }
  }

  const deleteThisAnswer = (id) => {
    dispatch(deleteAnswer(id))
  }

  const actionCorrectAnswer = (answerId) => {
    setAnswerValue('correct', answerId)
  }

  const actionFalseAnswer = (answerId) => {
    setAnswerValue('false', answerId)
  }

  return (
    <>
      {question && answer && parent && (
        <div
          className={`answer-textbox ${toolbarVisible ? '' : 'hide-toolbar'}`}
          id={`answer-textbox-${answer.id}`}
          style={{
            backgroundColor: openColorModal ? backgroundColor : answer.bgColor,
            fontSize: relativeFontSize(
              question.scale * 0.8,
              parent.width,
              parent.height,
              pageType
            ),
            fontColor: '#333',
            position: 'relative',
          }}
        >
          <div id={`toolbar-answer-${answer.id}`}>
            <span className="ql-formats">
              <button
                id="ql-custom-button-correct-answer"
                className={answer?.correct === 1 ? 'active-answer' : ''}
                onClick={() => actionCorrectAnswer(answer.id)}
                title="Vaihda vastauksen tila: oikein"
              >
                <FontAwesomeIcon icon={['far', 'thumbs-up']} />
              </button>
              <button
                id="ql-custom-button-false-answer"
                className={answer?.correct === 0 ? 'active-answer' : ''}
                onClick={() => actionFalseAnswer(answer.id)}
                title="Vaihda vastauksen tila: väärin"
              >
                <FontAwesomeIcon icon={['far', 'thumbs-down']} />
              </button>
            </span>
          </div>
          <div
            id={`answer-editor-${answer.id}`}
            ref={editorRef}
            className="quill-editor-custom-class"
            style={{
              padding: relativePaddingsForBlockRow('text'),
            }}
          ></div>
          <Button
            onClick={() => setOpenDeleteAnswerModal(true)}
            label="Poista vastausvaihtoehto."
            className="delete-answer-button"
          >
            <FontAwesomeIcon icon="times" className="cancel-icon" aria-hidden />
          </Button>
          {openDeleteContentModal && (
            <DeleteItemDialogue
              openDeleteContentModal={openDeleteContentModal}
              setOpenDeleteContentModal={setOpenDeleteContentModal}
              deleteItem={emptyContents}
              textContent={[
                'Haluatko varmasti tyhjentää vastausvaihtoehdon tekstin?',
                'Kyllä, tyhjennä',
              ]}
            />
          )}
          {openDeleteAnswerModal && (
            <DeleteItemDialogue
              openDeleteContentModal={openDeleteAnswerModal}
              setOpenDeleteContentModal={setOpenDeleteAnswerModal}
              deleteItem={() => deleteThisAnswer(answer.id)}
              textContent={[
                'Haluatko varmasti poistaa vastausvaihtoehdon?',
                'Kyllä, poista',
              ]}
            />
          )}
          {openColorModal && (
            <ColorPicker
              openColorModal={openColorModal}
              setOpenColorModal={setOpenColorModal}
              changeFont={changeFont}
              textbox={answer}
              color={changeFont ? fontColor : backgroundColor}
              setColor={changeFont ? setFontColor : setBackgroundColor}
              cancelAndCloseColorPicker={cancelAndCloseColorPicker}
              chooseColor={changeFont ? updateFontColor : saveBackgroundColor}
            />
          )}
        </div>
      )}
    </>
  )
}

AnswerTextEditor.propTypes = {
  answer: propTypes.instanceOf(Object).isRequired,
  question: propTypes.instanceOf(Object).isRequired,
  parent: propTypes.instanceOf(Object).isRequired,
  dragging: propTypes.bool,
  width: propTypes.number.isRequired,
  pageType: propTypes.number,
}

AnswerTextEditor.defaultProps = {
  dragging: false,
}

export default AnswerTextEditor
