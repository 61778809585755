import React, { useEffect, useState, useRef } from 'react'
import propTypes from 'prop-types'
import Quill from 'quill'
import ColorPicker from '../../ColorPicker'
import DeleteItemDialogue from '../../DeleteItemDialogue'
import { updateBlock } from '../../../store/blocks/blockAction'
import { useDispatch } from 'react-redux'
import {
  relativeFontSize,
  relativePaddingsForBlockRow,
  replaceSpacesWithNbsp,
} from '../../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TextEditor = ({
  blockbox,
  parent,
  editorContent,
  setEditorContent,
  setDirty,
  saveBlock,
  // dragging,
  width,
  pageType,
}) => {
  const editorRef = useRef()
  const quillRef = useRef()
  const [openDeleteContentModal, setOpenDeleteContentModal] = useState(false)
  const [openColorModal, setOpenColorModal] = useState(false)
  const [changeFont, setChangeFont] = useState(false)
  const [fontColor, setFontColor] = useState('black')
  const [backgroundColor, setBackgroundColor] = useState(
    blockbox && blockbox.bgColor ? blockbox.bgColor : 'transparent'
  )
  const [undoLevelCount, setUndoLevelCount] = useState(0)
  const [toolbarVisible, setToolbarVisible] = useState(false)
  // const [persistToolbar, setPersistToolbar] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      editorRef.current &&
      !editorRef?.current?.classList.contains('ql-container')
    ) {
      const quill = new Quill(editorRef.current, {
        theme: 'snow',
        modules: {
          toolbar: `#toolbar-${blockbox.id}`,
          history: {
            delay: 1500,
            maxStack: 500,
            userOnly: false,
          },
        },
        placeholder: 'Kirjoita blokkiin...',
      })

      quill.root.setAttribute('spellcheck', false)
      quillRef.current = quill

      if (editorContent) {
        const modifiedContent = replaceSpacesWithNbsp(editorContent)
        quillRef.current.clipboard.dangerouslyPasteHTML(modifiedContent)
      }
    }
  }, [editorContent, editorRef.current])

  useEffect(() => {
    const editorElement = editorRef.current
    if (editorElement) {
      const quillEditor = quillRef.current

      const handleFocus = () => {
        setToolbarVisible(true)
      }

      const handleBlur = (event) => {
        // Tarkista, onko blur-tapahtuman kohde työkalupalkin sisällä
        if (!event.relatedTarget || !event.relatedTarget.closest('.ql-toolbar')) {
          setToolbarVisible(false)
        }
      }

      const handleTextChange = () => {
        const html = quillEditor.root.innerHTML
        if (editorContent !== html) {
          setEditorContent(html)
          setDirty(true)
        }
      }

      quillEditor.root.addEventListener('focus', handleFocus)
      quillEditor.root.addEventListener('blur', handleBlur, true)
      quillEditor.on('text-change', handleTextChange)

      return () => {
        quillEditor.root.removeEventListener('focus', handleFocus)
        quillEditor.root.removeEventListener('blur', handleBlur)
        quillEditor.off('text-change', handleTextChange)
      }
    }
  }, [quillRef.current])

  // useEffect(() => {
  //   if (editorRef && editorRef.current && dragging) {
  //     setPersistToolbar(true)
  //     editorRef.current.ui.hide()
  //   } else if (editorRef && editorRef.current) {
  //     setPersistToolbar(false)
  //   }
  // }, [dragging])

  const emptyContents = () => {
    setDirty(true)
    saveBlock(null)
    if (quillRef.current) {
      quillRef.current.setText('')
    }
  }

  const changeFontColor = () => {
    setOpenColorModal(true)
    setChangeFont(true)
  }

  useEffect(() => {
    const quill = quillRef.current
    if (changeFont && quill) {
      quill.focus()
      quill.format('color', fontColor)
      setUndoLevelCount(undoLevelCount + 1)
    }
  }, [fontColor])

  useEffect(() => {
    if (!changeFont && editorRef && editorRef.current && editorRef.current.getBody) {
      editorRef.current.getBody().style.backgroundColor = backgroundColor
    }
  }, [backgroundColor])

  const saveBackgroundColor = () => {
    const updatedBlock = {
      ...blockbox,
      bgColor: backgroundColor,
    }
    dispatch(updateBlock(updatedBlock))
    editorRef.current.focus()
    setOpenColorModal(false)
  }

  const updateFontColor = () => {
    setOpenColorModal(false)
    setChangeFont(false)
    editorRef.current.focus()
    setUndoLevelCount(0)
    if (
      changeFont &&
      editorRef &&
      editorRef.current &&
      editorRef.current.selection
    ) {
      if (editorRef.current.formatter.match('textcolor')) {
        editorRef.current.formatter.remove('textcolor')
        editorRef.current.formatter.apply('textcolor')
        setEditorContent(editorRef.current.getContent())
        setDirty(true)
      } else {
        editorRef.current.formatter.apply('textcolor')
        setEditorContent(editorRef.current.getContent())
      }
    }
  }

  const undoAction = () => {
    const quill = quillRef.current
    if (quill && quill.history?.stack?.undo?.length > 1) {
      quill.history.undo()
    }
  }

  const cancelAndCloseColorPicker = () => {
    if (!changeFont && blockbox) {
      setBackgroundColor(blockbox.bgColor)
      editorRef.current.focus()
    } else {
      undoAction()
      setUndoLevelCount(0)
    }
    closeColorPicker()
  }

  const closeColorPicker = () => {
    setOpenColorModal(false)
    if (setChangeFont) {
      setChangeFont(false)
    }
  }

  const toggleBold = () => {
    const quill = quillRef.current
    if (!quill) {
      return
    }

    const format = quill.getFormat()
    if (format.bold === true) {
      quill.format('bold', false)
    } else {
      quill.format('bold', true)
    }
  }

  const toggleUnderline = () => {
    const quill = quillRef.current
    if (!quill) {
      return
    }

    const format = quill.getFormat()
    if (format.underline === true) {
      quill.format('underline', false)
    } else {
      quill.format('underline', true)
    }
  }

  const toggleItalic = () => {
    const quill = quillRef.current
    if (!quill) {
      return
    }

    const format = quill.getFormat()
    if (format.italic === true) {
      quill.format('italic', false)
    } else {
      quill.format('italic', true)
    }
  }

  return (
    <>
      {blockbox && parent && (
        <div
          className={`block-textbox ${toolbarVisible ? '' : 'hide-toolbar'}`}
          id={`textbox-${blockbox.id}`}
          style={{
            backgroundColor: openColorModal ? backgroundColor : blockbox.bgColor,
            fontSize: relativeFontSize(
              blockbox.scale,
              parent.width,
              parent.height,
              pageType
            ),
            fontColor: '#333',
            position: blockbox.draggable ? 'fixed' : 'relative',
          }}
        >
          <div
            id={`toolbar-${blockbox.id}`}
            style={{ bottom: blockbox.h * parent.height }}
          >
            <span className="ql-formats">
              <button
                onClick={() => toggleBold()}
                title="Lihavoitu"
                style={{ fontWeight: 'bold' }}
              >
                B
              </button>
              <button
                onClick={() => toggleUnderline()}
                title="Alleviivaus"
                style={{ textDecoration: 'underline' }}
              >
                U
              </button>
              <button
                onClick={() => toggleItalic()}
                title="Kursivoitu"
                style={{
                  fontStyle: 'italic',
                  fontFamily: 'Georgia',
                  fontSize: '15px',
                  paddingTop: '4px',
                }}
              >
                I
              </button>
            </span>

            <span className="ql-formats">
              <button
                className="ql-list"
                value="bullet"
                title="Järjestämätön lista"
              ></button>
              <button
                className="ql-list"
                value="ordered"
                title="Järjestetty lista"
              ></button>
            </span>

            <span className="ql-formats">
              <select className="ql-align" title="Tasaus">
                <option value=""></option>
                <option value="center"></option>
                <option value="right"></option>
              </select>
            </span>

            <span className="ql-formats">
              <select className="ql-size" title="Tekstin koko">
                <option value="">Tekstikoko</option>
                <option value="small">Pieni teksti</option>
                <option value="">Keskikokoinen teksti</option>
                <option value="large">Isompi teksti</option>
                <option value="huge">Iso teksti</option>
              </select>
            </span>

            <span className="ql-formats">
              <button
                id="ql-custom-button-color"
                onClick={() => changeFontColor()}
                title="Vaihda tekstin väriä"
              >
                <FontAwesomeIcon icon="font" />
              </button>
              <button
                id="ql-custom-button-background-color"
                onClick={() => setOpenColorModal(true)}
                title="Vaihda tekstin taustaväriä"
              >
                <FontAwesomeIcon icon="fill-drip" />
              </button>
            </span>

            <span className="ql-formats">
              <button
                id="ql-custom-button-undo"
                onClick={() => undoAction()}
                title="Kumoa"
                // disabled={quillRef?.current?.history?.stack?.undo?.length > 1}
              >
                <FontAwesomeIcon icon="reply" />
              </button>
              <button
                id="ql-custom-button-empty"
                onClick={() => setOpenDeleteContentModal(true)}
                title="Tyhjennä tekstikenttä"
              >
                <FontAwesomeIcon icon={['far', 'trash-alt']} />
              </button>
            </span>
          </div>
          <div
            id={`editor-${blockbox.id}`}
            ref={editorRef}
            className="quill-editor-custom-class"
            width={width}
            style={{
              padding: relativePaddingsForBlockRow('text'),
            }}
          ></div>
          {openDeleteContentModal && (
            <DeleteItemDialogue
              openDeleteContentModal={openDeleteContentModal}
              setOpenDeleteContentModal={setOpenDeleteContentModal}
              deleteItem={emptyContents}
              textContent={[
                'Haluatko varmasti tyhjentää blokin tekstin?',
                'Kyllä, tyhjennä',
              ]}
            />
          )}
          {openColorModal && (
            <ColorPicker
              openColorModal={openColorModal}
              setOpenColorModal={setOpenColorModal}
              changeFont={changeFont}
              textbox={blockbox}
              color={changeFont ? fontColor : backgroundColor}
              setColor={changeFont ? setFontColor : setBackgroundColor}
              cancelAndCloseColorPicker={cancelAndCloseColorPicker}
              chooseColor={changeFont ? updateFontColor : saveBackgroundColor}
            />
          )}
        </div>
      )}
    </>
  )
}

TextEditor.propTypes = {
  blockbox: propTypes.instanceOf(Object).isRequired,
  parent: propTypes.instanceOf(Object).isRequired,
  editorContent: propTypes.string,
  setEditorContent: propTypes.func.isRequired,
  setDirty: propTypes.func.isRequired,
  saveBlock: propTypes.func.isRequired,
  dragging: propTypes.bool,
  width: propTypes.number.isRequired,
  pageType: propTypes.number,
}

TextEditor.defaultProps = {
  editorContent: null,
  dragging: false,
}

export default TextEditor
