import React, { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateUser } from '../../../api/users/users'
import { updateUserInState } from '../../../store/user/userAction'
import EditPassword from './EditPassword'
import EditEmail from './EditEmail'
import DeleteAccount from '../DeleteAccount'
import './styles.scss'

const EditAccount = () => {
  const [response, setResponse] = useState(null)
  const [hideEmail, setHideEmail] = useState(true)
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const changePassword = async (data) => {
    setResponse(null)
    if (user && !user.error) {
      const updatedData = {
        password1: data.password,
        password2: data.passwordConfirm,
      }
      const res = await updateUser(user.id, updatedData)
      if (res && res.data && res.data.success) {
        dispatch(updateUserInState())
      }
      setResponse(res)
    }
  }

  return (
    <>
      {user && (
        <div className="two-part-page-container">
          <div className="two-part-page-content">
            <div className="instructions">
              <h1 className="page-header">Omat tiedot</h1>
              <p>Vaihda salasana tai sähköpostiosoite.</p>
            </div>
            <div className="edit-account-forms-container">
              <h2>Muokkaa tietoja</h2>
              <p>
                Olet kirjautunut tunnuksella{' '}
                {hideEmail ? (
                  <button
                    onClick={() => setHideEmail(!hideEmail)}
                    style={{
                      fontWeight: 'bold',
                      backgroundColor: 'transparent',
                      border: 'none',
                      padding: '0',
                      textDecoration: 'underline',
                    }}
                  >
                    <span>Näytä tunnukseni</span>
                  </button>
                ) : (
                  <span style={{ fontWeight: 'bold' }}>
                    {user.email.toLowerCase()}
                  </span>
                )}
              </p>
              <EditPassword onSubmit={changePassword} response={response} />
              <p>
                Salasanan on oltava vähintään 8 merkkiä pitkä. Hyvässä salasanassa on
                pieniä ja isoja kirjaimia sekä erikoismerkkejä. Älä käytä samaa
                salasanaa kuin muissa palveluissa.
              </p>
              <EditEmail />
              <DeleteAccount />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default memo(EditAccount)
