export const opikeAuthorId = 3098744
//test opike 2918653
//test ville 2918650
//development 2918647

export const isOpikeUser = (id) => {
  return id === opikeAuthorId ? true : false
}

// use this function to temporarily disable functionalities
export const isTemporarilyDisabled = () => {
  return true
}

export const anonymousGetId = (items) => {
  if (Object.values(items) && Object.values(items).length > 0) {
    const lastItem = Object.values(items)[Object.values(items).length - 1]
    return lastItem.id + 1
  } else {
    return 0
  }
}

export const formatColor = (color) => {
  if (color && color[0] && color[0] !== '#') {
    return '#'.concat(color)
  } else if (!color) {
    return null
  }
  return color
}

export const getWebM = (filename) => {
  if (filename) return `${filename}/video.webm`
  else return null
}

export const getMP4 = (filename) => {
  if (filename) return `${filename}/video.mp4`
  else return null
}

export const getVideoThumbnail = (video) => {
  if (video && video.type === 1) {
    return `http://i.ytimg.com/vi/${video.filename}/default.jpg`
  } else if (video && video.filename && !video.filename.match(/upload/))
    return `${video.filename}/thumb`
  else return ''
}

export const convertToObject = (array) =>
  array.reduce((obj, item) => {
    obj[item.id] = item
    return obj
  }, {})

export const removeEmptyHtmlTags = (html) => {
  const newHtml = html
    .replace(/<span[^>]*>\s*<\/span>/gi, '')
    .replace(/<p[^>]*>\s*<\/p>/gi, '')
    .replace(/<small[^>]*>\s*<\/small>/gi, '')
    .replace(
      /<(b|font|i|small|span|strong|u|div|p|br)>\s*<\/(b|font|i|small|span|strong|u|div|p|br)>/gim,
      ''
    )
  return newHtml
}

export const replaceSpacesWithNbsp = (html) => {
  let modifiedHtml = html.replace(/ {2}/g, '&nbsp;&nbsp;')
  modifiedHtml = modifiedHtml.replace(/&nbsp; /g, '&nbsp;&nbsp;')
  modifiedHtml = modifiedHtml.replace(/ &nbsp;/g, '&nbsp;&nbsp;')
  return modifiedHtml
}

export const getParent = (parentRef) => {
  const rect = parentRef.current.getBoundingClientRect()
  return {
    x: rect.x,
    y: rect.y,
    width: rect.width,
    height: rect.height,
  }
}

export const getSizeAndPositionForImageOrVideo = (currentPage) => {
  if (currentPage) {
    if (currentPage.type === 0) {
      return { x: 0.075, y: 0.08, w: 0.425, h: 0.85 }
    } else if (currentPage.type === 1) {
      return { x: 0.52, y: 0.08, w: 0.425, h: 0.85 }
    } else if (currentPage.type === 2) {
      return { x: 0.18, y: 0.04, w: 0.64, h: 0.66 }
    } else if (currentPage.type === 4) {
      return {
        x:
          currentPage.type > 4 && currentPage.images.length > 0
            ? currentPage.images.length * 0.01
            : 0,
        y:
          currentPage.type > 4 && currentPage.images.length > 0
            ? currentPage.images.length * 0.01
            : 0,
        w: 1,
        h: 1,
      }
    }
    return {
      x:
        currentPage.type > 4 && currentPage.images.length > 0
          ? currentPage.images.length * 0.01
          : 0,
      y:
        currentPage.type > 4 && currentPage.images.length > 0
          ? currentPage.images.length * 0.01
          : 0,
      w: 0.3,
      h: 0.3,
    }
  }
}

export const getGridImageSizeAndPosition = (pagetype, position) => {
  if (pagetype === 6) {
    if (position === 0) {
      return { x: 0, y: 0, w: 0.5, h: 0.5 }
    } else if (position === 1) {
      return { x: 0.5, y: 0, w: 0.5, h: 0.5 }
    } else if (position === 2) {
      return { x: 0, y: 0.5, w: 0.5, h: 0.5 }
    } else {
      return { x: 0.5, y: 0.5, w: 0.5, h: 0.5 }
    }
  } else if (pagetype === 7) {
    if (position === 0) {
      return { x: 0, y: 0, w: 0.333, h: 0.5 }
    } else if (position === 1) {
      return { x: 0.333, y: 0, w: 0.333, h: 0.5 }
    } else if (position === 2) {
      return { x: 0.666, y: 0, w: 0.333, h: 0.5 }
    } else if (position === 3) {
      return { x: 0, y: 0.5, w: 0.333, h: 0.5 }
    } else if (position === 4) {
      return { x: 0.333, y: 0.5, w: 0.333, h: 0.5 }
    } else if (position === 5) {
      return { x: 0.666, y: 0.5, w: 0.333, h: 0.5 }
    }
  } else if (pagetype === 8) {
    if (position === 0) {
      return { x: 0, y: 0, w: 0.25, h: 0.333 }
    } else if (position === 1) {
      return { x: 0.25, y: 0, w: 0.25, h: 0.333 }
    } else if (position === 2) {
      return { x: 0.5, y: 0, w: 0.25, h: 0.333 }
    } else if (position === 3) {
      return { x: 0.75, y: 0, w: 0.25, h: 0.333 }
    } else if (position === 4) {
      return { x: 0, y: 0.333, w: 0.25, h: 0.333 }
    } else if (position === 5) {
      return { x: 0.25, y: 0.333, w: 0.25, h: 0.333 }
    } else if (position === 6) {
      return { x: 0.5, y: 0.333, w: 0.25, h: 0.333 }
    } else if (position === 7) {
      return { x: 0.75, y: 0.333, w: 0.25, h: 0.333 }
    } else if (position === 8) {
      return { x: 0, y: 0.666, w: 0.25, h: 0.333 }
    } else if (position === 9) {
      return { x: 0.25, y: 0.666, w: 0.25, h: 0.333 }
    } else if (position === 10) {
      return { x: 0.5, y: 0.666, w: 0.25, h: 0.333 }
    } else if (position === 11) {
      return { x: 0.75, y: 0.666, w: 0.25, h: 0.333 }
    }
  }
}

export const pageTypeIsFree = (type) => {
  const freePageTypes = [5, 15]
  if (freePageTypes.includes(type)) return true
  else return false
}

export const pageTypeIsGrid = (type) => {
  const gridPageTypes = [6, 7, 8]
  if (gridPageTypes.includes(type)) return true
  else return false
}

export const pageTypeIsNotFreeOrGrid = (type) => {
  const notFreeOrGridPageTypes = [0, 1, 2, 3, 4]
  if (notFreeOrGridPageTypes.includes(type)) return true
  else return false
}

export const pageTypeIsQuestion = (type) => {
  const questionPageTypes = [9]
  if (questionPageTypes.includes(type)) return true
  else return false
}

export const pageLayoutIsHorizontal = (type) => {
  const horizontalPageTypes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  if (horizontalPageTypes.includes(type)) return true
  else return false
}

export const relativeBlockImageHeight = (scale, pageWidth, pageHeight, pageType) => {
  const imageHeight = pageLayoutIsHorizontal(pageType)
    ? (pageWidth * 0.0791 * scale).toFixed(2)
    : (pageHeight * 0.0841 * scale).toFixed(2)
  // const area = pageWidth * pageHeight
  // const ratio = ((imageHeight * imageHeight) / area) * 100
  // console.log(`Kuvan pinta-alan osuus sivun pinta-alasta: ${ratio.toFixed(2)} %`)
  return `${imageHeight}px`
}

export const relativeFontSize = (scale, pageWidth, pageHeight, pageType) => {
  const fontSize = pageLayoutIsHorizontal(pageType)
    ? (pageWidth * 0.025 * scale).toFixed(2)
    : (pageHeight * 0.0266 * scale).toFixed(2)
  // const area = pageWidth * pageHeight
  // const ratio = ((fontSize * fontSize) / area) * 100
  // console.log(
  //   `Fontin yhden kirjaimen pinta-alan osuus sivun pinta-alasta: ${ratio.toFixed(
  //     2
  //   )} %`
  // )
  return `${fontSize}px`
}

export const relativeMarginsForBlockImage = (pageWidth) => {
  const margin = (pageWidth * 0.0075).toFixed(2)
  return `0px ${margin}px`
}

export const relativePaddingsForBlockRow = (row) => {
  if (row === 'image') {
    return `0.5em 0.5em 0`
  } else if (row === 'text') {
    return `0.5em`
  }
}

/* DEMO: Pystymallisen (A4) ruudukkopohjan ruutujen koordinaatit
  else if (pagetype === 9) {
    switch (position) {
      case 0:
        return { x: 0, y: 0, w: 0.25, h: 0.166 }
      case 1:
        return { x: 0.25, y: 0, w: 0.25, h: 0.166 }
      case 2:
        return { x: 0.5, y: 0, w: 0.25, h: 0.166 }
      case 3:
        return { x: 0.75, y: 0, w: 0.25, h: 0.166 }
      case 4:
        return { x: 0, y: 0.166, w: 0.25, h: 0.166 }
      case 5:
        return { x: 0.25, y: 0.166, w: 0.25, h: 0.166 }
      case 6:
        return { x: 0.5, y: 0.166, w: 0.25, h: 0.166 }
      case 7:
        return { x: 0.75, y: 0.166, w: 0.25, h: 0.166 }
      case 8:
        return { x: 0, y: 0.333, w: 0.25, h: 0.166 }
      case 9:
        return { x: 0.25, y: 0.333, w: 0.25, h: 0.166 }
      case 10:
        return { x: 0.5, y: 0.333, w: 0.25, h: 0.166 }
      case 11:
        return { x: 0.75, y: 0.333, w: 0.25, h: 0.166 }
      case 12:
        return { x: 0, y: 0.5, w: 0.25, h: 0.166 }
      case 13:
        return { x: 0.25, y: 0.5, w: 0.25, h: 0.166 }
      case 14:
        return { x: 0.5, y: 0.5, w: 0.25, h: 0.166 }
      case 15:
        return { x: 0.75, y: 0.5, w: 0.25, h: 0.166 }
      case 16:
        return { x: 0, y: 0.666, w: 0.25, h: 0.166 }
      case 17:
        return { x: 0.25, y: 0.666, w: 0.25, h: 0.166 }
      case 18:
        return { x: 0.5, y: 0.666, w: 0.25, h: 0.166 }
      case 19:
        return { x: 0.75, y: 0.666, w: 0.25, h: 0.166 }
      case 20:
        return { x: 0, y: 0.833, w: 0.25, h: 0.166 }
      case 21:
        return { x: 0.25, y: 0.833, w: 0.25, h: 0.166 }
      case 22:
        return { x: 0.5, y: 0.833, w: 0.25, h: 0.166 }
      case 23:
        return { x: 0.75, y: 0.833, w: 0.25, h: 0.166 }
    }
  } */
